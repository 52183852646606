<template>
  <Transition name="fade">
    <div v-if="isActive" tabindex="1" class="modal is-active">
      <div class="modal-background" @click="onClickClose"></div>
      <div class="animation-content">
        <div class="card">
          <div class="card-title">
            <span class="modal-title">
              <template v-if="isGS1">FUNCIONALIDAD GOLTIPSTER</template
              ><template v-else>FUNCIONALIDAD PREMIUM</template></span
            >
            <img src="/icons/icn-close-white.svg" alt="close" class="btn-close" @click="onClickClose" />
          </div>
          <div class="card-content">
            <span v-if="isGS1"
              >Esta es una funcionalidad GolTipster. Para utilizar esta funcionalidad, favor de hacer
            </span>
            <span v-else>Esta es una funcionalidad Premium. Para utilizar esta funcionalidad, favor de hacer </span>
            <span class="link-btn" @click="onClickLogin">Login</span> o
            <span class="link-btn" @click="onClickCreateAccount"
              >Crear cuenta <template v-if="isGS1">GolTipster</template><template v-else>Premium</template></span
            >.
            <br />
            <br />
            <div class="amount">$<span style="font-weight: 600"> 99</span> pesos al mes</div>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import { clickCrearCuentaPremium, clickLoginBlackPopUp, clickBtnBlackPopupCerrar } from '@/utils/analytics.js';
export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  watch: {
    isOpened: {
      immediate: true,
      handler: function(newValue) {
        this.isActive = newValue;
      },
    },
    isActive(value) {
      window.parent.postMessage({ message: 'modalShown', isShown: value }, '*');
      if (!value) this.$emit('closeTooltip');
    },
    displayPremiumMessage() {
      this.isActive = this.displayPremiumMessage;
    },
  },
  mounted() {
    this.isActive = this.isOpened;
  },
  computed: {
    ...mapGetters(['displayPremiumMessage']),
    ...mapGetters('general', ['getSectionAnalytics', 'getPreviewSectionAnalytics']),
    ...mapGetters('loginGeneral', ['getPremiumAccount', 'getIsGolstats', 'isGS1']),
    section() {
      return this.$route.meta.title;
    },
  },
  methods: {
    ...mapMutations('auth', ['SET_AUTH_COMPONENT']),
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('general', ['setSectionAnalytics', 'setPreviewSectionAnalytics']),
    onClickLogin() {
      let sectionPopUp = this.getSectionAnalytics.includes('Banner_Home') ? '' : this.getSectionAnalytics;
      clickLoginBlackPopUp({
        section: sectionPopUp,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.SET_AUTH_COMPONENT('Login');
      this.SET_PREMIUM_MESSAGE(false);
    },
    onClickCreateAccount() {
      this.setSectionAnalytics(
        `${this.getPreviewSectionAnalytics ? this.getPreviewSectionAnalytics + '_' : ''}${this.getSectionAnalytics}`,
      );
      clickCrearCuentaPremium({
        is_premium: this.getPremiumAccount,
        section: this.section,
        golstats: this.getIsGolstats,
        banner: this.getSectionAnalytics,
      });
      this.SET_AUTH_COMPONENT('SelectPlan');
      this.SET_PREMIUM_MESSAGE(false);
    },
    onClickClose() {
      let sectionPopUp = this.getSectionAnalytics.includes('Banner_Home') ? '' : this.getSectionAnalytics;
      clickBtnBlackPopupCerrar({
        section: sectionPopUp,
        is_premium: this.getPremiumAccount,
        golstats: this.getIsGolstats,
      });
      this.setSectionAnalytics('Banner_Home');
      this.SET_PREMIUM_MESSAGE(false);
    },
  },
};
</script>

<style scoped>
.btn-close {
  position: absolute;
  top: 10px;
  right: 12px;
  cursor: pointer;
}
.link-btn {
  text-decoration: underline;
  cursor: pointer;
}
.modal-close {
  position: absolute;
  top: 10px;
  margin-right: -12px;
}
.modal-background {
  background-color: rgba(10, 10, 10, 0.2);
}
.card {
  background-color: rgba(0, 0, 0, 0.85);
  color: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 9px 19px 0 rgb(0 0 0 / 27%);
}
.card-title {
  display: flex;
  text-transform: uppercase;
  font-size: 20px;
  padding: 15px 15px 0px;
  justify-content: space-around;
  font-family: 'Avenir-Pro-Bold';
}
.amount {
  font-size: 20px;
}
.card-content {
  text-align: center;
  max-height: 420px;
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 15px 15px 20px;
  font-family: 'Avenir-Pro-Medium';
  font-size: 17px;
}
.animation-content {
  width: 550px;
  padding: 2rem;
}
@media screen and (max-width: 550px) {
  .card-title {
    padding: 30px 15px 10px;
    font-size: 14px;
  }
  .amount {
    font-size: 14px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media only screen and (max-width: 768px) {
  .card {
    padding: 20px;
    margin: 0px 5%;
  }
  .card-title {
    font-size: 17px;
  }
  .animation-content {
    width: 75%;
    padding: 0px;
  }
  .card-content {
    overflow: auto;
    padding: 1rem 0.9rem;
  }
  .amount {
    font-size: 17px;
  }
}
@media only screen and (max-width: 560px) {
  .card-content {
    margin-top: 5px;
  }
}
@media only screen and (max-width: 420px) {
  .card-title {
    font-size: 15px;
  }
  .amount {
    font-size: 15px;
  }
}
@media only screen and (max-width: 320px) {
  .card-title {
    font-size: 13px;
  }
  .amount {
    font-size: 13px;
  }
}
</style>
